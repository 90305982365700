import { createSlice } from '@reduxjs/toolkit'

const system = createSlice({
    name: "system",
    initialState: {
        language: "it",
        darkMode: false
    },
    reducers: {
        setLanguage(state, action){
            return {
                ...state,
                language: action.payload.language
            }
        },
        setDarkMode(state, action){
            return {
                ...state,
                darkMode: action.payload.darkMode
            }
        }
    }
})

export const { setLanguage, setDarkMode } = system.actions
export default system.reducer