
//process.env.NODE_ENV = 'production'
const environment = process.env.NODE_ENV || "development"

const applicationName = "HideReal"
const version = "v0.1"
console.log(applicationName, environment, version)

const globalConfig = {
    production: {
        applicationName: applicationName,
        devTools: false,
        version: version
    },
    development: {
        applicationName: applicationName,
        devTools: true,
        vesion: version
    }
}

export default globalConfig[environment]