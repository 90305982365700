import './App.css';
import { createBrowserRouter, Link, RouterProvider } from 'react-router-dom';
import LoginPage from './pages/LoginPage';

function App() {

  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <LoginPage></LoginPage>
      ),
    },
    {
      path: "about",
      element: <div>About</div>,
    },
  ]);

  return (
    <RouterProvider router={router}></RouterProvider>
  );
}

export default App;
