import { configureStore } from "@reduxjs/toolkit"
import globalConfig from "../globalConfig"

import system from "../slices/system"

export default configureStore({
    reducer: {
        system
    },
    devTools: globalConfig.devTools
})